import axios from 'axios';
export function chatbot(){
    const jsonUrl = '/chatbot.json';
    const chatTemplate = {
        data: function () {
            return {
                view: false,
                elem: '',
                message: '',
                box: [{
                    txt: 'こんにちは、適当に入力してみてください。',
                    class: 'owner',
                    count: 0,
                    date: '最初のメッセージ'
                }],
                res: [],
                count: 1
            }
        },
        template: `
            <div id="chatbot">
                <transition name="chat">
                    <a id="chatopen" v-if="!view" @click="view = true"><i class="far fa-comment-dots"></i></a>
                </transition>
                <transition name="chat">
                    <div class="botwrapeer" v-if="view">
                        <a class="botwrapeer__close" @click="view = false"></a>
                        <h4 class="botwrapeer__title"><i class="fas fa-comment-dots"></i>hotbot サンプル</h4>
                        <div class="botwrapeer__box" id="chatlist">
                            <ul class="botwrapeer__box__list">
                                <li class="user" v-for="item in box" :key="item.count" :id="'chatID' + item.count" :class="item.class" v-html="item.txt" :data-date="item.date"></li>
                            </ul>
                        </div>
                        <form class="botwrapeer__input" v-on:submit.prevent="comment()">
                            <input type="text" class="botwrapeer__input__area" placeholder="質問を入力" v-model="message">
                            <div class="botwrapeer__input__trg" @click="comment()"><i class="fas fa-paper-plane"></i></div>
                        </form>
                    </div>
                </transition>
            </div>
        `,
        methods: {
            comment: function(){
                if(this.message != ''){
                    this.count ++;
                    this.box.push(
                        {
                            txt: this.esc(this.message),
                            class: 'user',
                            count: this.count,
                            date: this.getdate(),
                        }
                    );
                    this.answer(this.esc(this.message));
                    this.message = '';
                }
            },
            answer: function(str){
                let result = this.randommessage();
                for (let i in this.res) {
                    let keyword = this.res[i].keyword;
                    let bool = this.matchs(keyword,str);
                    if(bool){
                        result = this.res[i].answer;
                        if(this.res[i].link != ''){
                            result += '<a href="'+this.res[i].link+'" target="_blank">該当ページ</a>'
                        }
                        break;
                    }
                }
                this.count ++;
                this.box.push(
                    {
                        txt: result,
                        class: 'owner',
                        count: this.count,
                        date: this.getdate(),
                    }
                );

                this.elem = document.getElementById('chatlist');
                if(this.elem){
                    let $elem = this.elem;
                    setTimeout(function(){
                        $elem.scrollTop = $elem.scrollHeight;
                    },100);
                }
            },
            esc: function(str){
              return (str + '').replace(/&/g,'&amp;')
                               .replace(/"/g,'&quot;')
                               .replace(/'/g,'&#039;')
                               .replace(/</g,'&lt;')
                               .replace(/>/g,'&gt;');
            },
        	matchs: function(arg, str){
        		let boolarg = [];
        		let bool = true;
        		for(let i = 0; i < arg.length; i++){
        			if(str.indexOf(arg[i]) > -1){
        				boolarg[i] = true;
        			}else{
        				boolarg[i] = false;
        			}
        		}
        		for(let i = 0; i < boolarg.length; i++){
        			if(!boolarg[i]){
        				bool = false
        			}
        		}
        		return bool;
        	},
        	randommessage: function(){
        		let ram = 1 + Math.floor(Math.random() * 10);
        		let result = '最適な結果が見つかりませんでした。';
        		if(ram > 8){
        			result = 'よくわかりません。';
        		}else if(ram > 5){
        			result = '！？';
        		}else if(ram > 3){
        			result = '言いたいことはわかる。';
        		}
        		return result;
        	},
        	getdate: function(){
        		const japanStandardTime = new Date().toLocaleString({ timeZone: 'Asia/Tokyo' });
        		const y = new Date(japanStandardTime).getFullYear();
        		const m = new Date(japanStandardTime).getMonth();
        		const j = new Date(japanStandardTime).getDate();
        		const h = new Date(japanStandardTime).getHours();
        		const i = new Date(japanStandardTime).getMinutes();
        		return y+'.'+m+'.'+j+' '+h+':'+i;
        	}

        },
        mounted (){
            axios
              .get(jsonUrl)
              .then(response => (this.res = response.data));
        }
    };


    return chatTemplate;
}
