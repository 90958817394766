import {chatbot} from './components/chatbot';
import Vue from 'vue/dist/vue.min';
document.addEventListener('DOMContentLoaded', function() {
    const chat = new Vue({
		el:'#elem',
		components: {
			'chatbot': chatbot()
		}
	});
});
